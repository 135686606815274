


import { Component } from "vue-property-decorator";
import { BASE_API_URL, CLIENT_URL } from "@/config";
import Axios from "axios";

import { mixins } from "vue-class-component";
import CommonMixin from "../../../mixins/common.mixin";
import { HollowDotsSpinner } from "epic-spinners";
import UpdateUserInfo from "@/views/UpdateUserInfo.vue";
import UpdateUserStatus from "@/views/UpdateUserStatus.vue";
import UpdateBrokerInfo from "@/views/UpdateBrokerInfo.vue";
import ProfilePicture from "@/views/ProfilePicture.vue";

@Component({
  components: {
    HollowDotsSpinner,
    UpdateUserInfo,
    UpdateUserStatus,
    UpdateBrokerInfo,
    ProfilePicture,
  },
})
export default class ListBrokerComponent extends mixins(CommonMixin) {
  public brokers: any = [];
  public tokenGenerator: any = "";
  public brokerId = "";
  public currentAgreementBrokerData = null;
  public callUpdateUserInfoComp = false;
  public personalInfo = [];
  public brokerLoader = false;
  public currentLengthOnScroll = 0;
  public brokerLen = 0;
  public searchByType = null;
  public searchString = null;
  public skip = 0;
  public borrowerExist = false;
  public borrowerMessage = null;
  public deleteOpt = false;
  public delbrokerId = null;
  public showLoaderScroll = false;
  public searchStatus = "all";
  public userType = null;
  public isActiveSubmitNewLoan:any = null;
  public isMandatoryBrokerOnboarding:any = false;
  public numberViewedBrokers = 0;
  public editUserData: any = {};

  // code to generateToken
  public async generateUrl(userId) {
    try {
      let body = {
        userId: userId,
        user: "broker"
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/saveTokenGenerator",
        body);
      if (response.data.token) {
        this.tokenGenerator =
          CLIENT_URL + "activation-page?brokerToken=" + response.data.token;
      }
      if (response.status == 200) {
        this.brokerId = userId;
        this.$modal.show("generateToken");
      } else {
        this.$snotify.error("Please try after some time");
      }
    } catch (error) {
      console.log(error);
    }
  }
  // SEND URL TO BROKER EMAIL
  public async sendEmailToBroker() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "broker/sendUrlLink",
        { userId: this.brokerId, token: this.tokenGenerator });
      if (response.status == 200) {
        this.$snotify.success("Email sent Successfully");
        this.$modal.hide("generateToken");
      }
    } catch (error) {}
  }

  // TO COPY TEXT IN INPUT FIELD
  public async copyLink() {
    var copyText: any = document.getElementById("myInput");
    copyText.select();
    document.execCommand("copy");
  }

  public showAgreement(data) {
    try {
      if (data && data.agreementPath) {
        this.currentAgreementBrokerData = data.agreementPath;
        this.$modal.show("contractModal");
        this.viewBrokerAgreement();
      } else {
        this.$snotify.error("Broker has not accepted the Processing Agreement");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public downloadAgreement() {
    try {
      let path = this.currentAgreementBrokerData;
      this.$store.state.displayFiles.downloadFilesArray = [path];
      this.downloadDocs(true);
    } catch (error) {
      console.log(error);
    }
  }

  public async ListBroker(mountCall: boolean = false) {
    try {
      const limit = 50;
      this.skip = mountCall ? 0 : this.skip + limit;
      if (mountCall) {
        this.brokerLoader = true;
        await this.getTotalBrokerCount();
      }
      const response = await Axios.post(
        BASE_API_URL + "superAdmin/listBrokers",
        {
          userId: this.$userId,
          options: {
            skip: this.skip,
            limit,
          },
          searchByType: this.searchByType,
          searchString: this.searchString != "" && this.searchString != null ? this.searchString : null,
          userType: this.$userType,
          status: this.searchStatus,
        });

      this.brokers = mountCall ? response.data.broker : this.brokers.concat(response.data.broker);
      this.brokers = JSON.parse(JSON.stringify(this.brokers));
      this.currentLengthOnScroll = this.brokers.length;
    } catch (error) {
      console.error(error);
    } finally {
      this.brokerLoader = false;
      this.showLoaderScroll = false;
    }
  }
  public capitalizeFirstCharacter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  public getFullName(broker) {
    let firstName = broker.firstName ? broker.firstName : "";
    let lastName = broker.lastName ? broker.lastName : "";
    return (
      this.capitalizeFirstCharacter(firstName) +
      " " +
      this.capitalizeFirstCharacter(lastName)
    );
  }
  public getValueOnChange(type) {
    if (this.searchByType == null) {
      this.brokers = [];
      this.searchByType = null;
      this.searchString = null;
      this.ListBroker(true);
    }
    this.numberViewedBrokers = 0;
  }
  public getBrokerDetails(broker) {
    this.$router.replace({
      path: "/super-admin/broker-detail",
      query: { userId: broker.userId, userType:broker.userType}
    });
  }

  public async openModal(broker, i) {
    try {
      let sure = false;
      let msg = !broker.userActive
        ? "Are you sure you want to deactivate broker?"
        : "Are you sure you want to activate broker?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          broker.userActive = !broker.userActive;
          this.$snotify.info("No changes");
        });
      if (sure) {
        let loanTxnId = broker.userId;
        let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: loanTxnId,
            activate: broker.userActive,
            userType: broker.userType
          });
        let message = response.data.userActive
          ? "User has been activated"
          : "User has been deactivated";
        this.$snotify.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async openModalLock(broker, i) {
    try {
      let sure = false;
      const msg = broker.requireBrokerToContactWemlo ? `Are you sure you want to unlock this broker?` : `Are you sure you want to lock this broker?`;
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.$snotify.info("No changes");
        });
      if (sure || broker.requireBrokerToContactWemlo) {
        let response = await Axios.post(
          BASE_API_URL + "superAdmin/switchBrokerLock",
            {
                userId: broker.userId,
                requireBrokerToContactWemlo: !broker.requireBrokerToContactWemlo,
            }
        );

        broker.requireBrokerToContactWemlo = !broker.requireBrokerToContactWemlo;
        const message = response.data.requireBrokerToContactWemlo
          ? "Broker has been locked"
          : "Broker has been unlocked";
        this.$snotify.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async openModalClearedToSubmit(broker) {
    try {
      let sure = false;
      const msg = broker.clearedToSubmit ? `Are you sure you want to uncheck the broker as Cleared to Submit?` : `Confirm you wish to mark the broker as Cleared to Submit`;
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.$snotify.info("No changes");
        });
      if (sure || broker.clearedToSubmit) {
        let response = await Axios.post(
          BASE_API_URL + "superAdmin/switchClearedToSubmit",
            {
                userId: broker.userId,
                clearedToSubmit: !broker.clearedToSubmit,
            }
        );

        broker.clearedToSubmit = !broker.clearedToSubmit;
        const message = response.data.clearedToSubmit
          ? "Broker has been marked as Cleared to Submit"
          : "Broker has been unchecked as Cleared to Submit";
        this.$snotify.success(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public updateEmail(type, broker) {
    this.callUpdateUserInfoComp = true;
    let data = {
      userType: broker.userType,
      modalName: type
    };
    data["firstName"] = broker.firstName ? broker.firstName : "";
    data["lastName"] = broker.lastName ? broker.lastName : "";
    data["userId"] = broker.userId;
    data["userActive"] = broker.userActive;
    data["email"] = broker.email;
    data["cellPhone"] = broker.phone;
    data["inActiveBroker"] = broker.haveDetail ? false : true;
    this.personalInfo = [];
    this.personalInfo.push(data);

    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }

  public async handleInfiniteScroll() {
    if (!this.showLoaderScroll && this.currentLengthOnScroll < this.brokerLen) {
      this.showLoaderScroll = true;
      await this.ListBroker(false);
    }
  }

  public async getTotalBrokerCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getTotalBrokerCount/",
        {
          userId: this.$userId,
          countStatus: this.searchStatus,
          count: "brokerCount",
          searchByType: this.searchByType,
          searchString: this.searchString != "" && this.searchString != null ? this.searchString : null,
        });
      this.brokerLen = response.data.brokerLen;
    } catch (error) {
      console.log(error);
    }
  }
  public async deleteBroker(brokerId, brokerName) {
    try {
      this.delbrokerId = brokerId;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/deleteBroker",
        {
          userId: brokerId,
          name: brokerName
        });
      if (response.data.code == 420) {
        this.borrowerExist = true;
        this.borrowerMessage = response.data.message;
      } else {
        this.borrowerExist = true;
        this.deleteOpt = true;
        this.borrowerMessage = response.data.message;
      }
      this.$modal.show("brokerDeleteModal");
    } catch (error) {
      console.log(error);
    }
  }
  public async deleteBrokerInfo(id) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/deleteBrokerInfo",
        {
          userId: id
        });
      if (response.data.code == 200) {
        this.closeBrokerDelModal();
        this.$snotify.success(response.data.message);
        await this.getTotalBrokerCount();
        await this.ListBroker(true);
      }
    } catch (error) {
      console.log(error);
    }
  }
  public closeBrokerDelModal() {
    this.borrowerExist = false;
    this.delbrokerId = null;
    this.borrowerMessage = null;
    this.deleteOpt = false;
    this.$modal.hide("brokerDeleteModal");
  }
  public getFirstLetter(companyName) {
    return companyName.charAt(0);
  }

  /*********************************************************************************************************
 *      Function Get Wemlo Setting Collection
 *********************************************************************************************************/
  public async getWemloSettingDetails(){
    try{
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getWemloSettingDetails",
        {
          userId: this.$userId,
        });
        if(response.status == 200) {
          this.isActiveSubmitNewLoan = response.data.status;
          this.isMandatoryBrokerOnboarding = response.data.statusMandatoryBrokerOnboarding;
        }
    }catch(error){
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }
  /**************************************************************************************
   *      Function Update Wemlo Setting Collection 
   *****************************************************************************************/
  public async changeWemloSetting(action, settingName = 'newloans'){
    try {
      settingName === 'newloans' ?
          this.$modal.hide('newLoanConformationModal')
          : this.$modal.hide('newBrokerOnboardingConformationModal');

      if(!action){
        if (settingName === 'onboarding') {
            this.isMandatoryBrokerOnboarding = !this.isMandatoryBrokerOnboarding;
        } else {
            this.isActiveSubmitNewLoan = !this.isActiveSubmitNewLoan;
        }
        return;
      }
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/changeWemloSetting",
        {
          userId: this.$userId,
          status: this.isActiveSubmitNewLoan,
          statusMandatoryBrokerOnboarding: this.isMandatoryBrokerOnboarding,
        });
        this.$snotify.clear()
        if(response.status == 200)
          this.$snotify.success((this.isActiveSubmitNewLoan ? 'Enabled' : 'Disabled') + ' Sucessfully')
    }catch(error){
      console.log(error);
    }
    this.$store.state.wemloLoader = false;
  }

  public async imageLoad(path, index) {
    try {
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path, hideError: true  });
      if (response.status === 201) throw new Error("not found");
      if (!response.data) {
        this.setLetterInsteadAvatar(index);
      } else {
        const dom = document.getElementById('imageEl'+index);
        const img = document.createElement('img');
        img.src = `data:image/${path.split('.').pop()};base64,${response.data}`;
        img.className = 'broker-list-profile';
        dom.innerHTML = "";
        dom.appendChild(img);
      }
    } catch (error) {
      this.setLetterInsteadAvatar(index);
    }
  }

  public setLetterInsteadAvatar(index) {
    if (this.brokers[index]) {
      const letter = this.getFirstLetter(this.brokers[index].companyName);
      const dom = document.getElementById('imageEl'+index);
      dom.innerHTML = `<span v-else class='superadmin-processor-icon'> ${letter} </span>`;
    }
  }

  private async viewBrokerAgreement () {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(BASE_API_URL+ 'common/fetchDocument', { path: this.currentAgreementBrokerData });
      let iframe = document.getElementById('pdfElement');
      iframe['src'] = 'data:application/pdf;base64,'+response.data;
      iframe.append();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  public editUser(broker) {
    this.editUserData = Object.assign({}, broker);
    if (this.editUserData) this.$modal.show("brokerInfo");
  }


  async beforeMount() {
    await this.getWemloSettingDetails();
    await this.getTotalBrokerCount();
    this.ListBroker(true);
    this.userType = this.$userType;
  }
}
