






















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import { UserData } from "../models/common.model";

import Axios from "axios";

@Component({})
export default class UpdateBrokerInfo extends Vue {
    @Prop()
    public editUserData: UserData;
    @Prop()
    public typeEdit: string;
    @Prop()
    public propData;

    public stateCountyLoader = false;
    public saveInProcess = false;
    public fetchState = [];
    public stateList = [];

    public async getCityAndState() {
        try {
        this.stateCountyLoader = true;

        let zip = this.editUserData.address.zip;
        let response = await Axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=country:US|postal_code:" +
            zip,
            { headers: { public: true}}
        );
        if (response.status == 200 && response.data.status == "OK") {
            response.data.results[0]["address_components"].forEach(element => {
            if (element.types.includes("locality") || (!this.editUserData.address.city.name && element.types.includes("administrative_area_level_3"))) {
                this.editUserData.address.city.name = element.long_name;
            }

            if (element.types.includes("administrative_area_level_1")) {
                this.editUserData.address.state.name = element.long_name;
                this.editUserData.address.state.code = element.short_name;
                this.fetchState.push({
                name: element.long_name,
                code: element.short_name
                });
            }
            });
        }else if (response.data.status == "ZERO_RESULTS") {
            this.stateList.forEach(state => {
            this.fetchState.push({ name: state.state, code: state.code });
            });
        }
        this.stateCountyLoader = false;
        } catch (error) {
        this.stateCountyLoader = false;
        }
    }

    public async ValidateAndSubmit() {
        try {
        let formValid = await this.$validator.validateAll("brokerForm");
        if (!formValid) return;
        this.saveInProcess = true;
        this.editUserData.email = this.editUserData.email.toLowerCase();
        delete this.editUserData._id;
        let link = (this.typeEdit && this.typeEdit == 'superAdmin') ? "broker/updateBrokerDetailsBySuperAdmin" : "broker/updateBrokerDetails";
        let response = await Axios.post(
            BASE_API_URL + link,
            this.editUserData);

        if (response.status == 200) {
            this.$emit("callMountFunction", this.propData);
            this.$snotify.success("User updated successfully");
        } else {
            this.$snotify.error("Error occurred during updating user");
        }
        this.saveInProcess = false;
        this.$modal.hide("brokerInfo");
        } catch (error) {
        this.saveInProcess = false;
        console.log(error.response);
        }
    }

    public checkIfStateHasCode() {
        let state = this.editUserData.address.state.name;
        this.stateList.forEach(f => {
        if (f.state == state) {
            this.editUserData.address.state.code = f.code;
        }
        });
    }

    public fixEmptyAddressFields() {
        let address = {
            street: null,
            unit: null,
            zip: null,
            city: { name: null },
            state: { name: null },
        }
        this.editUserData.address = {...address, ...this.editUserData.address};
    }

    beforeMount() {
        this.fixEmptyAddressFields();
        this.getCityAndState();
    }
}
