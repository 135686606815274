


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import { HollowDotsSpinner } from "epic-spinners";
import { mixins } from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";

@Component({ components: { HollowDotsSpinner } })
export default class ListBrokerComponent extends mixins(CommonMixin) {
  public brokers = [];
  public dotLoader = false;
  public currentAgreementBrokerData = null;
  public allBrokers = [];
  public searchList = [];
  public searchName = "";
  public brokerLoader = false;
  public loadMoreScroll = false;
  public currentLengthOnScroll = 0;
  public brokerLen = 0;
  public searchByType = null;
  public searchString = null;
  public skip = 0;
  public searchStatus="all"

  public async ListBroker(mountCall) {
    try {
      if (mountCall) {
        this.skip = 0;
        this.brokerLoader = true;
        await this.getTotalBrokerCount();
      } else 
        this.skip = this.skip + 25;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/listBrokers",
        {
          userId: this.$userId,
          options: {
            skip: this.skip,
            limit: 25,
          },
          searchByType: this.searchByType,
          searchString: this.searchString != "" && this.searchString != null ? this.searchString : null,
          userType: this.$userType,
          status: this.searchStatus,
      });
      this.brokers = mountCall ? response.data.broker : this.brokers.concat(response.data.broker)
      this.currentLengthOnScroll = this.brokers.length;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    } finally {
      this.brokerLoader = false;
    }
  }
  public getValueOnChange() {
    if (this.searchByType == null) {
      this.brokers = [];
      this.searchByType = null;
      this.searchString = null;
      this.ListBroker(false);
    }
  }
  public getBrokerDetails(brokerId) {
    this.$router.replace("/processor/broker-detail?brokerId=" + brokerId);
  }

  public showAgreement(data) {
    try {
      if (data && data.agreementPath) {
        this.currentAgreementBrokerData = data.agreementPath;
        this.$modal.show("contractModal");
        this.viewBrokerAgreement();
      } else {
        this.$snotify.error("Broker has not accepted the Processing Agreement");
      }
    } catch (error) {
      console.log(error);
    }
  }
  public downloadAgreement() {
    try {
      let path = this.currentAgreementBrokerData;
      this.$store.state.displayFiles.downloadFilesArray = [path];
      this.downloadDocs(true);
    } catch (error) {
      console.log(error);
    }
  }

  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.brokerLen) {
      this.loadMoreScroll = true
      await this.ListBroker(false);
      this.loadMoreScroll = false
    }
  }
  public async getTotalBrokerCount() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getTotalBrokerCount/",
        {
          userId: this.$userId,
          userType: this.$userType,
          countStatus : this.searchStatus,
          count:"brokerCount",
          searchByType: this.searchByType,
          searchString: this.searchString != "" && this.searchString != null ? this.searchString : null,
        });
      this.brokerLen = response.data.brokerLen;
    } catch (error) {
      console.log(error);
    }
  }

  private async viewBrokerAgreement() {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: this.currentAgreementBrokerData });
      let iframe = document.getElementById('pdfElement');
      iframe['src'] = 'data:application/pdf;base64,'+response.data;
      iframe.append();
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.ListBroker(true);
    this.getTotalBrokerCount();
  }
}
