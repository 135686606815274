







import Axios from "axios";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

@Component
export default class Picture extends Vue {
  
  @Prop()
  public data: string;

  @Prop()
  public path: string;

  public blob: string = null;
  public observer: IntersectionObserver = null;

  get imageSource() {
    if (this.blob) {
      return this.blob;
    } else if (this.data) {
      return this.data;
    }
    return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII";
  }

  async fetchImage() {
    try {
      if (!this.path) {
        return;
      }

      let response = await Axios.get(BASE_API_URL + "common/fetchImage", {
        responseType: "blob",
        params: {
          path: this.path,
        },
      });

      this.blob = URL.createObjectURL(response.data);

      /**
       * Emit fetch event to reuse image in other components
       */
      this.$emit('fetch', this.blob);
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.fetchImage();
          this.observer.unobserve(this.$el);
        }
      })
    });

    this.observer.observe(this.$el);
  }

  destroyed() {
    this.observer.disconnect();
  }
}
