













import { Component, Prop, Vue } from "vue-property-decorator";
import Picture from '@/views/Picture.vue';

@Component({
  components: {
    Picture,
  },
})
export default class ProfilePicture extends Vue {
  
  @Prop()
  public path: string;

  @Prop()
  public firstLetter: string;

  @Prop()
  public big: boolean;

  onFetchImage(image) {
    this.$emit('fetch', image);
  }
}
